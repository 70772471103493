<template>
  <div class="epidemicPreventForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="表单类型"
        :rules="[
          { required: true, message: '请选择表单类型', trigger: 'blur' },
        ]"
        prop="formsType"
      >
        <v-select :width="width" :options="formType" v-model="form.formsType" />
      </el-form-item>
      <el-form-item
        label="表单名称"
        :rules="[
          { required: true, message: '请输入表单名称', trigger: 'blur' },
        ]"
        prop="formsName"
      >
        <v-input
          placeholder="请输入表单名称"
          :width="width"
          v-model="form.formsName"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="表单说明"
        :rules="[
          { required: true, message: '请输入表单说明', trigger: 'blur' },
        ]"
        prop="formsExplain"
      >
        <v-ueditor :maxLength="20000" v-model="form.formsExplain" />
      </el-form-item>
      <div class="addBtn">
        <v-button text="新增选项" @click="toAddSelect" />
      </div>
      <div class="workSheet">
        <v-table :tableData="form.requestList" ref="vTable" :headers="headers">
          <template #operateTeSlot="scope">
            <div class="opera-btn">
              <v-button
                text="编辑"
                type="text"
                @click="toEdit(scope.row.row, scope.row.$index)"
              />
              <v-button
                text="删除"
                type="text"
                @click="toDelete(scope.row.row, scope.row.$index)"
              />
            </div>
          </template>
        </v-table>
      </div>
      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="status"
      >
        <v-select
          :width="width"
          :options="formPostType"
          v-model="form.status"
        />
      </el-form-item>
      <v-dialog
        title="新增登记选项"
        v-model="isdialog"
        @cancel="cancel"
        @confirm="confirm"
        cancelTxt="关闭"
        sureTxt="保存"
      >
        <el-form-item
          label="名称"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <v-input
            placeholder="请输入名称"
            :width="width"
            v-model="item.problemName"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="类型"
          :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]"
        >
          <v-select
            :width="width"
            :options="formProType"
            v-model="item.problemType"
            @change="changeFormProType"
          />
        </el-form-item>
        <el-form-item
          v-for="(oItem, index) in item.selectList"
          :key="index"
          :label="'选项' + (index + 1)"
          :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
        >
          <div class="selectItem">
            <v-input
              placeholder="请输入"
              :width="width"
              v-model="oItem.value"
            ></v-input>
            <div class="handle">
              <v-button
                v-if="
                  item.selectList.length > 1 &&
                  index == item.selectList.length - 1
                "
                type="success"
                text="删除"
                @click="toDeleteSelect"
              />
              <v-button
                v-if="index == item.selectList.length - 1"
                text="新增"
                type="success"
                @click="toAddSelectItem"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="是否必填"
          :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        >
          <v-radio :radioObj="radioObj" v-model="item.ifRequired"></v-radio>
        </el-form-item>
      </v-dialog>
    </form-panel>
  </div>
</template>

<script>
import {
  epidemicPreventEditUrl,
  epidemicPreventAddUrl,
  epidemicPDetailUrl,
} from "./api.js";
import {
  formType,
  isTopMap,
  sexStatusMap,
  formProType,
  formProTypeMap,
  formPostType,
  formPostTypeMap,
} from "./map.js";

export default {
  name: "epidemicPreventForm",
  data() {
    return {
      radioObj: [
        { name: "1", value: "是" },
        { name: "0", value: "否" },
      ],
      formProType,
      formPostType,
      isdialog: false,
      formType,
      width: 220,
      oData: [],
      submitConfig: {
        queryUrl: epidemicPDetailUrl,
        submitUrl: epidemicPreventAddUrl,
      },
      headers: [
        {
          prop: "problemName",
          label: "名称",
        },
        {
          prop: "problemType",
          label: "类型",
          formatter: (row, prop) => {
            return formProTypeMap[row[prop]] || "--";
          },
        },
        {
          prop: "ifRequired",
          label: "是否必填",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "optionValue",
          label: "选项值",
        },
      ],
      tableData: [],
      form: {
        formsId: null,
        formsType: "",
        formsName: "",
        formsExplain: "",
        status: "",
        scene: "",
        page: "pages/targetUrl/index",
        requestList: [],
      },
      item: {
        problemName: "",
        problemType: "",
        ifRequired: "0",
        optionValue: "",
        selectList: [],
      },
      oIndex: "",
      isEdit: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    const { id } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({
          formsId: id,
        });
      });
      this.submitConfig.submitUrl = epidemicPreventEditUrl;
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    toDeleteSelect() {
      this.item.selectList.pop();
    },
    toAddSelectItem() {
      for (var i = 0; i <= this.item.selectList.length - 1; i++) {
        if (!this.item.selectList[i].value) {
          this.$message.info("请填完选项");
          return;
        }
      }
      this.item.selectList.push({ value: "" });
    },
    changeFormProType(value) {
      if (value == 1) {
        this.item.selectList = [];
        this.item.optionValue = "";
        this.item.selectList.push({ value: "" });
      } else {
        this.item.optionValue = "";
        this.item.selectList = [];
      }
    },
    confirm() {
      if (!this.item.problemName) {
        this.$message.info("请填写名称");
        return;
      }
      if (!this.item.problemType) {
        this.$message.info("请选择类型");
        return;
      }
      for (var i = 0; i <= this.item.selectList.length - 1; i++) {
        if (!this.item.selectList[i].value) {
          this.$message.info("请填完选项");
          return;
        }
      }
      if (this.item.problemType == 1) {
        this.item.optionValue = this.item.selectList
          .map((ele) => ele.value)
          .join(",");
      }
      if (this.isEdit) {
        this.$set(this.form.requestList, this.oIndex, this.item);
      } else {
        this.form.requestList.push(this.item);
      }
      console.log(this.form.requestList, "--=");
      this.$forceUpdate();
      this.isdialog = false;
    },
    cancel() {},
    toAddSelect() {
      this.isEdit = false;
      this.oIndex = "";
      this.isdialog = true;
      this.item = {
        problemName: "",
        problemType: "",
        ifRequired: "0",
        optionValue: "",
        selectList: [],
      };
    },
    toEdit(row, index) {
      this.oIndex = index;
      this.isEdit = true;
      row.selectList = [];
      if (row.optionValue) {
        let arr = row.optionValue.split(",");
        arr.forEach((ele) => {
          row.selectList.push({ value: ele });
        });
      }
      let obj = JSON.stringify(row);
      this.item = JSON.parse(obj);
      this.isdialog = true;
    },
    toDelete(row, index) {
      this.form.requestList.splice(index, 1);
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (data.detailsVOS) {
        this.form.requestList = data.detailsVOS;
      }
    },
    submitBefore() {
      this.form.scene = `4&9&param:${JSON.stringify({
        formsType: this.form.formsType,
      })}`;
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicPreventForm {
  box-sizing: border-box;
  height: 100%;
  .addBtn {
    padding: 0 40px;
  }
  .workSheet {
    padding: 40px;
    height: 400px;
    overflow-y: auto;
  }

  .selectItem {
  }
  .handle {
    margin: 10px 0 0;
    text-align: right;
    .v-button {
      margin-left: 10px;
    }
  }
}
</style>
